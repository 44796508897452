.add_step__CLZ {
    width: 250px;
    height: 36px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
}
.icon_step__CLZ {
    width: 30px;
    height: 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 100%;
    opacity: 1;
}
.icon_nbr_click__CLZ {
    display: grid;
    height: 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    opacity: 1;
    grid-template-columns: 20px auto 1px 3px;
}

.borderless_input__CLZ {
    border: none;
    width: 90%;
    border-radius: 6px;
    height: 100%;
}

.borderless_input__CLZ:focus {
    outline: none;
}
.styled-Modal-wrapper__clz {
    max-width: 1700px !important;
}
.disabledDiv__CLZ {
    pointer-events: none;
    opacity: 0.4;
}
.preview-text {
    white-space: nowrap;
    overflow: hidden;
    max-width: 28ch;
    text-overflow: ellipsis;
}
.preview-textCard {
    white-space: nowrap;
    overflow: hidden;
    max-width: 32ch;
    text-overflow: ellipsis;
}
.preview-textStep {
    white-space: nowrap;
    overflow: hidden;
    max-width: 44ch;
    text-overflow: ellipsis;
}
.preview-textStep-card {
    white-space: nowrap;
    overflow: hidden;
    max-width: 21ch;
    text-overflow: ellipsis;
}
