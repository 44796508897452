.btn_annuler {
    cursor: pointer;
    border: transparent;
    background-color: transparent;

    text-align: center;
    text-decoration: underline;
    font: normal normal normal 15px/15px Poppins;
    letter-spacing: 0px;
}
.scroll__clz {
    height: 600px;
    max-height: 550px;
    overflow-y: auto;
}
.scrollAjouter__clz {
    min-height: calc(100vh - 370px);
    max-height: calc(100vh - 370px);
    overflow-y: auto;
}

.option_btn__clz {
    min-height: 55px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    opacity: 1;
}
.SubOption_btn__clz {
    min-height: 55px;
    width: 80%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    opacity: 1;
}
.DivSubOption {
    text-align: -webkit-center;
    margin-left: 8%;
}
.content_font__clz {
    color: var(--unnamed-color-2a3042);
    text-align: left;
    font: normal normal normal Segoe UI;
    letter-spacing: 0px;
    color: #2a3042;
    opacity: 1;
}
.title_font__clz {
    font-weight: bold;
    color: var(--unnamed-color-2a3042);
    text-align: left;
    font: normal normal 600 Segoe UI;
    letter-spacing: 0px;
    color: #2a3042;
    opacity: 1;
}
.center_NoDataIcon {
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}
