.dropdown_custom {
    box-shadow: none !important;
    &:hover {
        color: white !important;
    }
}
.ModalPublish {
    max-width: 600px;
}
.react-datepicker__cl {
    width: 70px;
}
.fieldsetInterne__clz {
    border: 1px solid #dedede;
    border-radius: 5px;
    opacity: 1;

    overflow-y: auto;
    overflow-x: hidden;
}
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 0px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}
.badge_en_attente {
    background: #f7e1c6 0% 0% no-repeat padding-box;
    border-radius: 20px;
    top: 702px;
    left: 808px;
    width: 145px;
    height: 36px;
    padding: 7px;
}
.badge_en_prod {
    background: #d6eed6 0% 0% no-repeat padding-box;
    border-radius: 20px;
    top: 702px;
    left: 808px;
    width: 145px;
    height: 36px;
    padding: 7px;
}
