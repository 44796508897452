@media (max-width: 768px) {
    .MuiToolbar-root {
        flex-wrap: wrap !important;
    }
    .RvYSC {
        margin-top: 0.5rem !important;
    }
    #tag {
        min-height: auto !important;
    }
}
.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 35px !important;
    min-height: 35px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: unset;
    transition: unset;
}
