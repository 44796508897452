.new-input-toggle__clz {
    position: absolute;
    padding-top: 0.25rem !important;
    right: 15px;
    opacity: 0.6;
}
.invalid_styled_clz {
    /* border-color: #f46a6a !important; */
    border-style: solid;
    border-width: 1px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.valid_styled_clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* .invalidcounter_clz {
    margin-top: -2.25rem !important;
}
.counter_clz {
    margin-top: -1rem !important;
    /* margin-top: -2.25rem !important; */
/* } */

.counter_clz {
    position: absolute;
    top: 65px;
}
