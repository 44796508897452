.gestionDeArticle_clz {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 25ch; /* 10 caractères en largeur */
}
.modal-backdrop.show {
    opacity: 0.3;
}
.modal-backdrop.fade {
    opacity: 0.3;
}
