.Style_image {
    width: 22px;
    height: 23px;
    background: transparent linear-gradient(180deg, #9f4500 0%, #9f4500 100%) 0%
        0% no-repeat padding-box;
}
.inpt {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn_ {
    color: #000000 !important;
    border-radius: 12px;
    border-color: #74788d !important;
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.border_input {
    border: "0px !important";
}
.form-controll {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.94rem + 2px);
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px !important ;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.overflow_item {
    overflow-y: scroll !important;
    /* max-height: 93vh !important; */
    overflow-x: hidden;
}

.widthbtn {
    width: 70;
}
.height_ {
    height: "65px";
}
@media (max-width: 768px) {
    .height_ {
        height: auto;
    }
    .MuiToolbar-root {
        flex-wrap: wrap !important;
    }
    .RvYSC {
        margin-top: 0.5rem !important;
    }
    #mytable {
        min-height: 45vh !important;
    }
}
.Footercard {
    background-color: white;
    border-color: currentcolor rgba(0, 0, 0, 0.125) rgba(0, 0, 0, 0.125);
    border-style: none solid solid;
    border-width: 0px 1px 1px;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px 0px 12px 12px !important;
}
.Bodycard {
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.Headercard {
    background-color: white;
    margin-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.invalid_styled_clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 35px !important;
    min-height: 35px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: unset;
    transition: unset;
}
.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.form-control:disabled {
    background-color: #f2f2f2 !important;
}
.breadcrumb {
    background-color: #f3f3f9;
}
