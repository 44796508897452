@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
/* .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content-wrap {
    flex: 1;
} */
body {
    overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    body {
        overflow-y: auto;
    }
}
@media screen and (max-width: 550px) {
    .footer__clz {
        font-size: 9px;
    }
}

.page-content {
    display: grid;
    grid-template-rows: auto auto 1fr;
}
.container-fluid {
    display: grid;
    grid-template-rows: auto 1fr;
}

.page-wrapper {
    /* background: #eef5f9; */
    position: relative;
    transition: 0.2s ease-in;
    display: block;
    margin-top: 100px;
}

#main-wrapper {
    width: 100%;
    overflow: hidden;
    margin: 0;
}
.sidebar-wrapper {
    position: fixed;
}

.left-sidebar {
    position: absolute;
    width: 220px;
    height: 100%;
    top: 0px;
    z-index: 10;
    padding-top: 0;
    background: #fff;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    /* transition: 0.2s ease-in; */
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    /* transition: transform 0.6s ease; */
    transition-delay: 0.4s;
}
.headercss {
    position: fixed;
    z-index: 10;
    padding: 0;
    margin: 0;
}

.responsive-toast {
    width: 300px;
}

@media (min-width: 993px) {
    .responsive-toast {
        width: 600px;
    }
}

#loading {
  display: inline-block;
  color: rgba(68, 185, 252, 0.3);
  width: 50px;
  height: 50px;
  border: 3px solid rgba(68, 185, 252, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.centeranimation {
  display: flex;
  flex-direction: row;
  margin-top: 20%;
  justify-content: center;
}

#listfilter {
    max-height: calc(-630px + 100vh) !important;
}

.PrintersTable_checkbox__clz__3mz5r {
    width: 18px;
    height: 18px;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.Style_image {
    width: 22px;
    height: 23px;
    background: transparent linear-gradient(180deg, #9f4500 0%, #9f4500 100%) 0%
        0% no-repeat padding-box;
}
.inpt {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn_ {
    color: #000000 !important;
    border-radius: 12px;
    border-color: #74788d !important;
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.border_input {
    border: "0px !important";
}
.form-controll {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.94rem + 2px);
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px !important ;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.overflow_item {
    overflow-y: scroll !important;
    /* max-height: 93vh !important; */
    overflow-x: hidden;
}

.widthbtn {
    width: 70;
}
.height_ {
    height: "65px";
}
@media (max-width: 768px) {
    .height_ {
        height: auto;
    }
    .MuiToolbar-root {
        flex-wrap: wrap !important;
    }
    .RvYSC {
        margin-top: 0.5rem !important;
    }
    #mytable {
        min-height: 45vh !important;
    }
}
.Footercard {
    background-color: white;
    border-color: currentcolor rgba(0, 0, 0, 0.125) rgba(0, 0, 0, 0.125);
    border-style: none solid solid;
    border-width: 0px 1px 1px;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px 0px 12px 12px !important;
}
.Bodycard {
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.Headercard {
    background-color: white;
    margin-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.invalid_styled_clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 35px !important;
    min-height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: unset;
}
.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.form-control:disabled {
    background-color: #f2f2f2 !important;
}
.breadcrumb {
    background-color: #f3f3f9;
}

.AdvancedImage_cell_divided__clz__oqGX- {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    position: relative;

    background-image: linear-gradient(
        to right top,
        white 49%,
        #cccfd3 50%,
        white 51%
    );
}

.AdvancedImage_cell_topRight__clz__1j3Vl {
    grid-column-start: 2;
    text-align: right;
    margin: auto;
}

.AdvancedImage_cell_bottomLeft__clz__2nwd1 {
    grid-column-start: 1;
    margin: auto;
}

.AdvancedImage_advanced_image_table__LWWXQ th {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 40px;
    border: 1px solid #cccfd3;
}
.AdvancedImage_advanced_image_table__LWWXQ td {
    margin: 0;
    padding: 0;
    position: relative;
    border: 1px solid #cccfd3;
}

.new-input-toggle__clz {
    position: absolute;
    padding-top: 0.25rem !important;
    right: 15px;
    opacity: 0.6;
}
.invalid_styled_clz {
    /* border-color: #f46a6a !important; */
    border-style: solid;
    border-width: 1px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.valid_styled_clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* .invalidcounter_clz {
    margin-top: -2.25rem !important;
}
.counter_clz {
    margin-top: -1rem !important;
    /* margin-top: -2.25rem !important; */
/* } */

.counter_clz {
    position: absolute;
    top: 65px;
}

.item_font__CLZ {
    font: 13px / 17px "Segoe UI";
    color: rgb(73, 80, 87);
}

th,
td {
    text-align: center;
    vertical-align: middle;
}
.Line__CLZ {
    -webkit-transform: rotate(23deg);
    transform: rotate(23deg);

    border: 1px solid #ececed;
}
.item_font__CLZ {
    font: 13px / 17px "Segoe UI";
    color: rgb(73, 80, 87);
}

.borderless_input__CLZ {
    border: none;
    width: calc(100% - 15px);
    border-radius: 6px;
    height: 100%;
}

.borderless_input__CLZ:focus {
    outline: none;
}

.container_quantity {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}
.CompositionDeBae_clz {
    width: 100%;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.btn_annuler {
    cursor: pointer;
    border: transparent;
    background-color: transparent;

    text-align: center;
    text-decoration: underline;
    font: normal normal normal 15px/15px Poppins;
    letter-spacing: 0px;
}
.btn_valider {
    width: 107px;
    height: 47px;
}
.h5 {
    text-align: left;
    font: normal normal 600 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.saisir {
    width: 170px;
    height: 47px;
    opacity: 0.5;
}
.select {
    min-width: 170px !important;
    width: 47px !important;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #707070 !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}
.modification {
    background-color: #eeeeee;
    width: 1463px;
    height: 70px;
}
.scrollAdd__clz {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
}
.scroll__clz {
    height: 595px;
    max-height: 595px;
    overflow-y: auto;
}
.card-container__clz {
    min-height: 110px !important;
    height: 110px !important;
    background: #f3f3f3 !important;
    border: 1px solid #d4d4d4 !important;
    border-radius: 10px !important;
    opacity: 0.69 !important;
    margin-top: -15px;
}
.card-time__clz {
    min-height: 80px !important;
    height: 80px !important;
    width: 360px !important;
}
.card_time_planning__clz {
    height: 80px !important;
    min-height: 80px !important;
    width: -webkit-fill-available !important;
}
.card_title_planning {
    min-height: 40px !important;
    height: 40px !important;
    width: -webkit-fill-available !important;
}
.checkbox__clz {
    width: 25px;
    height: 25px;
    border: 1px solid var(--unnamed-color-d6d6d6);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    opacity: 1;
}
.checkbox_planning__clz {
    width: 25px;
    height: 25px;
    border: 1px solid var(--unnamed-color-d6d6d6);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    opacity: 1;
    margin-top: -5%;
    margin-right: 10px;
}
.heur__clz {
    margin-left: -25px;
}
.InputHeur__clz {
    margin-right: -100px;
}
.InputDate__clz {
    margin-left: -5%;
}
.MarginCardHeur__clz {
    margin-top: -1% !important;
    margin-left: auto !important;
}
.fieldset__clz {
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.fieldsetInterne__clz {
    border: 1px solid #dedede;
    border-radius: 5px;
    opacity: 1;
}
/* */
.header {
    padding-right: 50px;
    height: 81px;
    background: #e8e8e8 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
}
.header__input {
    width: 329px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    margin-left: 5px;
}
.header__label {
    margin-left: 5px;
    margin-right: 10px;
}
p {
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
    margin-bottom: 5px !important;
}
.body__conteneur {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
    height: 291px;
    margin-top: 10px;
}
.ant-radio-wrapper {
    margin: 10px !important;
}
.ant-radio {
    height: 19px;
    opacity: 1;
    margin-left: 25px;
}
.body__input {
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    margin-left: 5px;
}
.input__body {
    /* //padding: 0px 20px ; */
    width: 300;
    padding-bottom: 10px !important;
}
.input__body p {
    width: -webkit-max-content !important;
    width: max-content !important;
}

/* .editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 5px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 5px 5px;
    border: 1px solid #e21616;
    border-radius: 2px;
    margin: 0;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #25da25;
} */
.editable-cell {
    position: fixed;
}
.editable-cell-value-wrap {
    width: 200px !important;
    height: 25px !important;
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    padding: 0% !important;
    /* border: none !important; */
}
.editable-row {
    width: 200px !important;
    /* border: #1fb7e6 1px solid;  */
    margin: 0px !important;
    padding: 0% !important;
}
.ant-input:hover {
    /* border-color: #e2e2e2fb !important; */
    width: 200px !important;
    box-shadow: none !important;
    border: none !important;
}

.ant-input sm {
    border-color: #e2e2e2fb !important;
    width: 200px !important;
    box-shadow: none !important;
    border: none !important;
}
.ant-input sm ::-webkit-input-placeholder {
    /* border-color: #e2e2e2fb !important; */
    outline: 0;
    padding: 50px;
    /* border: none !important; */
}
.ant-input sm :-ms-input-placeholder {
    /* border-color: #e2e2e2fb !important; */
    outline: 0;
    padding: 50px;
    /* border: none !important; */
}
.ant-input sm ::placeholder {
    /* border-color: #e2e2e2fb !important; */
    outline: 0;
    padding: 50px;
    /* border: none !important; */
}
/* .ant-input ::placeholder{
    color:red !important
} */
td.ant-table-cell-row-hover {
    background: none !important;
}
.ant-table-row-level-1 {
    width: 200px !important;
    /* border: #ff1a8c 1px solid; */
    padding: 0% !important;
    border: none !important;
}
/* .ant-form-item{
    width: 200px !important;
    border: #cdf009 1px solid;
    padding: 0px !important;
    margin: 0px !important;
} */
.ant-form-item-control-input-content {
    width: 200px !important;
    /* border: none !important; */
    /* border: #e2e2e2 1px solid !important; */
    padding: 0px !important;
    margin: 0px !important;
}

/* .ant-form-item-control-input{ width: 200px !important; 
    border: #af1818 1px solid !important;
    padding: 0px !important;
    margin: 0px !important;} */

.ant-row {
    width: 200px !important;
    /* border: #ce1515 1px solid;  */
    padding: 0% !important;
    border: none !important;
}
.ant-form-item-row {
    width: 200 !important;
    height: 25 !important;
    /* border: #050000 1px solid; */
    padding: 0% !important;
    border: none !important;
}
.ant-input-sm {
    padding: 0px 7px;
    border: none;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}
.ant-table-thead > tr > th {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #ffffff;
    /* border: 1px solid #E2E2E2 !important; */
    border-bottom: 1px solid #c6c6c6 !important;
    transition: background 0.3s ease;
}
.ant-table-thead {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e2 !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}
.ant-table-tbody > tr > td {
    border-bottom: 1px solid #e2e2e2;
    transition: background 0.3s;
}

.categorie__filtre {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.categorie__options {
    width: 218px;
}
.multipleEdit__button {
    width: 174px;
    height: 45px;
    background: #2f2f2f 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
    margin-left: 50px;
    margin-top: 18px;
    margin-bottom: 10px;
    color: #fcfcfc;
}
.filtre__box {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e2;
    opacity: 1;
    border-radius: 4px;
}
.filtre {
    width: 219px;
    margin-left: 50px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.filtre__box > p {
    /* text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 50px; */

    padding: 20px;
    border-right: 1px solid #e2e2e2;
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
label {
    margin: 0px;
}

.ant-table-pagination.ant-pagination {
    margin: 0;
    display: none;
}

.ant-table-tbody > tr > td {
    border-bottom: 1px solid #e2e2e2;
    transition: background 0.3s;
    text-align: left;
    font: normal normal normal 16px/20px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
.ant-table-thead > tr > th {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #ffffff;
    /* border: 1px solid #E2E2E2 !important; */
    border-bottom: 1px solid #c6c6c6 !important;
    transition: background 0.3s ease;
    height: 22px;
    font: normal normal 600 17px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
.kwBQUT {
    font-family: "Segoe UI Semibold";
    color: #2b2828;
    font-size: 16px;
    /* width: 116px; */
    height: 21px;
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
.css-1okebmr-indicatorSeparator {
    display: none;
}
.css-tj5bde-Svg {
    height: 25px;
    fill: #2b2828 !important;
}

.ant-table-wrapper {
    clear: both;

    height: 250px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    opacity: 1;
}
.ant-table-wrapper::-webkit-scrollbar {
    width: 0 !important;
}
.ant-form-item {
    margin: 0px;
}

pp {
    text-align: left;
    font: normal normal bold 15px/14px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    text-transform: uppercase;
    opacity: 1;
}

.ant-table-wrapper {
    height: 400px;
}
.ant-table-tbody > tr > td {
    padding: 0.11%0%;
}

.form-group {
    margin-bottom: 0rem !important ;
}
.styled-input-wrapper__clz > div {
    margin-bottom: 0px !important ;
}
.btn_plus {
    border: 1px solid #ced4da;
    border-radius: 6px;
}
.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: unset;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

@media (max-width: 768px) {
    .MuiToolbar-root {
        flex-wrap: wrap !important;
    }
    .RvYSC {
        margin-top: 0.5rem !important;
    }
    #tag {
        min-height: auto !important;
    }
}
.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 35px !important;
    min-height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: unset;
}

.modal_clz {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    white-space: nowrap;
}
.tooltip_clz {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.Style_image {
    width: 22px;
    height: 23px;
    background: transparent linear-gradient(180deg, #9f4500 0%, #9f4500 100%) 0%
        0% no-repeat padding-box;
}
.inpt {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn_ {
    color: #000000 !important;
    border-radius: 12px;
    border-color: #74788d !important;
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.Footercard {
    background-color: white !important;
    border-color: currentcolor rgba(0, 0, 0, 0.125) rgba(0, 0, 0, 0.125);
    border-style: none solid solid;
    border-width: 0px 1px 1px;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px 0px 12px 12px !important;
}
.Bodycard {
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: white;
}
.Headercard {
    background-color: white !important;
    margin-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: 66px !important;
}
.Footercard {
    background-color: white;
    border-color: currentcolor rgba(0, 0, 0, 0.125) rgba(0, 0, 0, 0.125);
    border-style: none solid solid;
    border-width: 0px 1px 1px;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px 0px 12px 12px !important;
}
.Bodycard {
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.Headercard {
    background-color: white;
    margin-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
@media (max-width: 1367px) {
    .height_ {
        height: auto;
    }
    .MuiToolbar-root {
        flex-wrap: wrap !important;
    }
    .RvYSC {
        margin-top: 0.5rem !important;
    }
    #mytable {
        min-height: 45vh !important;
    }
}
.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 35px !important;
    min-height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: unset;
}
@media screen and (max-width: 1200px) {
    .modal-dialog {
        max-width: 100%;
        max-height: 100vh;
        margin-top: -0.5vh;
    }
}

.page-card-carte:hover .page-content_Carte {
    background: transparent linear-gradient(180deg, #3a3a3a 0%, #5b5b5b 100%) 0%
        0% no-repeat padding-box !important;
    box-shadow: 0px 5px 10px #00000042;
    border: 1px solid #dbdbdb;
    border-radius: 20px;
    opacity: 1;
}
.page-content_Carte {
    width: 100%;
}
.page-card-carte:hover .card-title {
    color: #ffffff !important ;
}
.page-card-carte:hover .card-text {
    color: #ffffff !important ;
}
.page-card-carte :hover .select_carte {
    opacity: 0 !important ;
}
.page-card-carte :hover.icon {
    fill: #cf080842 !important ;
    stop-color: #cf080842 !important ;
    background: transparent
        linear-gradient(180deg, #cf080842 0%, #cf080842 100%) 0% 0% no-repeat
        padding-box !important;
}
@media (max-width: 1894px) {
    .T-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media (min-width: 576px) {
    .modal-dialogshop {
        max-width: 1500px;
        margin: 1.75rem auto;
    }
}

.swal-content {
    margin-top: 25px !important;
}

.dark-bg__clz {
    background: #292929 !important;
}

.active-card-theme__clz {
    background: #464647 !important;
    box-shadow: 0px 14px 23px -2px rgba(0, 0, 0, 0.29) !important;
}

.border-Footer__clz {
    border-bottom: 1px solid #ced4da;
}
.card_cl__clz {
    display: grid;
    grid-template-columns: 2.5fr 2fr;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    border: 0;
}
.style-card_clz {
    border-color: #ced4da !important;
    border-width: thin;
    /* border-style: solid !important; */
}
.card_c__clz {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    border: 0;
}
.fieldsetInterne__clz {
    border: 1px solid #dedede;
    border-radius: 5px;
    opacity: 1;
    min-height: 350px;
}

.react-datepicker__clz {
    width: 73px !important;
    height: 30px;
    font-size: 14px;
}
.style-modal__clz {
    width: 650px;
    min-width: 700px;
}

.btn-style__clz:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem white !important;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.react-datepicker__header {
    text-align: center;

    background-color: white !important;

    border-bottom: 1px solid #f0e7e7;

    border-top-left-radius: 0.3rem;

    padding: 8px 0;

    position: relative;
}

.react-datepicker__month-container {
    /* float: left; */
    width: 320px;

    max-width: 320px;

    /* overflow: auto; */

    height: 274px;

    max-height: 274px;
}

/* .react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;

    font-size: 1rem;

    color: #000;
}

.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;

    background-color: #242526;

    color: #fff;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;

    background-color: #0b0807 !important;

    color: #fff;
} */

.dropdown_custom {
    box-shadow: none !important;
    &:hover {
        color: white !important;
    }
}
.ModalPublish {
    max-width: 600px;
}
.react-datepicker__cl {
    width: 70px;
}
.fieldsetInterne__clz {
    border: 1px solid #dedede;
    border-radius: 5px;
    opacity: 1;

    overflow-y: auto;
    overflow-x: hidden;
}
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 0px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}
.badge_en_attente {
    background: #f7e1c6 0% 0% no-repeat padding-box;
    border-radius: 20px;
    top: 702px;
    left: 808px;
    width: 145px;
    height: 36px;
    padding: 7px;
}
.badge_en_prod {
    background: #d6eed6 0% 0% no-repeat padding-box;
    border-radius: 20px;
    top: 702px;
    left: 808px;
    width: 145px;
    height: 36px;
    padding: 7px;
}

.btn_ {
    color: #000000 !important;
    background-color: #ffffff;
    border-color: #74788d !important;
    border: 1px solid transparent;
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}
.arrowRight {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(320deg);
            transform: rotate(320deg);
}
.arrowDown {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.makeStyles-tableHeadCell-4 {
    color: rgba(0, 0, 0, 0.87);
    padding: 16px 1px 16px 16px;
    font-size: 0.875rem;
    text-align: left;
    font-weight: 500;
    line-height: 1.5rem;
    border-right: 1px solid rgba(224, 224, 224, 1);
    vertical-align: inherit;
    font-weight: bold;
}
/* table {
    overflow-x: auto;
} */
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}

.page-title-box {
    align-items: center !important;
    padding-bottom: 0% !important;
}
.nav {
    display: flex;
    height: 100%;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000d;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    opacity: 1;
    justify-content: space-evenly !important;
    align-content: center;
    padding: 10px 5px;
}
.nav-item {
    height: -webkit-max-content !important;
    height: max-content !important;
    align-items: center !important;
    font-size: 16px;
    background: #525353 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: white !important;
}
.espace {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
/** Mobile **/
@media (max-width: 450px) {
    .nav-item {
        height: -webkit-max-content !important;
        height: max-content !important;
        background: #525353 0% 0% no-repeat padding-box;
        opacity: 1;
        align-items: center !important;
        margin-top: 1rem !important;
        font-size: 12px;
    }
    .espace {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .filepath {
        min-width: 80% !important;
    }
}

.footer2 {
    position: inherit !important;
}

.disabledNav {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #323333;
    opacity: 0.84;
    color: black !important;
}
.disabledDiv {
    pointer-events: none;
    opacity: 0.4;
}

.filepath {
    min-width: 50%;
    border: "aqua 1px solid";
    padding-left: 20px;
    height: 36px;
    background: #f3f3f9 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}


.App {
    font-family: sans-serif;
    text-align: center;
    padding: 1rem;
}

.input-path {
    min-width: 50%;
    border: "aqua 1px solid";
    padding-left: 20px;
    height: 36px;
    background: #f3f3f9 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
}
.icon {
    margin-bottom: 0px !important;
}
.input_ty {
    font-size: 18px;
    margin-bottom: 0rem;
    width: 19% !important;
}
.SearchInput {
    height: "50%";
}
.btn_add_clz {
    background: #1d4afe 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: white;
    borderradius: "10px";
    height: "35px";
}
.btn_update_clz {
    background: #ff8000 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    background: "#1D4AFE";
    color: white;
    borderradius: "10px";
    height: "35px";
}

.btn_annuler {
    cursor: pointer;
    border: transparent;
    background-color: transparent;

    text-align: center;
    text-decoration: underline;
    font: normal normal normal 15px/15px Poppins;
    letter-spacing: 0px;
}
.scroll__clz {
    height: 600px;
    max-height: 550px;
    overflow-y: auto;
}
.scrollAjouter__clz {
    min-height: calc(100vh - 370px);
    max-height: calc(100vh - 370px);
    overflow-y: auto;
}

.option_btn__clz {
    min-height: 55px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    opacity: 1;
}
.SubOption_btn__clz {
    min-height: 55px;
    width: 80%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    opacity: 1;
}
.DivSubOption {
    text-align: -webkit-center;
    margin-left: 8%;
}
.content_font__clz {
    color: var(--unnamed-color-2a3042);
    text-align: left;
    font: normal normal normal Segoe UI;
    letter-spacing: 0px;
    color: #2a3042;
    opacity: 1;
}
.title_font__clz {
    font-weight: bold;
    color: var(--unnamed-color-2a3042);
    text-align: left;
    font: normal normal 600 Segoe UI;
    letter-spacing: 0px;
    color: #2a3042;
    opacity: 1;
}
.center_NoDataIcon {
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}

.gestionDeArticle_clz {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 25ch; /* 10 caractères en largeur */
}
.modal-backdrop.show {
    opacity: 0.3;
}
.modal-backdrop.fade {
    opacity: 0.3;
}

.overflow_categories {
    overflow-y: scroll !important;
    /* max-height: 95vh !important; */
    overflow-x: hidden;
}
.custom_radio input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    width: 20%;
    height: 20px;
}
.form-check-label {
    margin-bottom: 0;
    width: 100%;
    margin-left: 20px;
}

.add_step__CLZ {
    width: 250px;
    height: 36px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
}
.icon_step__CLZ {
    width: 30px;
    height: 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 100%;
    opacity: 1;
}
.icon_nbr_click__CLZ {
    display: grid;
    height: 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    opacity: 1;
    grid-template-columns: 20px auto 1px 3px;
}

.borderless_input__CLZ {
    border: none;
    width: 90%;
    border-radius: 6px;
    height: 100%;
}

.borderless_input__CLZ:focus {
    outline: none;
}
.styled-Modal-wrapper__clz {
    max-width: 1700px !important;
}
.disabledDiv__CLZ {
    pointer-events: none;
    opacity: 0.4;
}
.preview-text {
    white-space: nowrap;
    overflow: hidden;
    max-width: 28ch;
    text-overflow: ellipsis;
}
.preview-textCard {
    white-space: nowrap;
    overflow: hidden;
    max-width: 32ch;
    text-overflow: ellipsis;
}
.preview-textStep {
    white-space: nowrap;
    overflow: hidden;
    max-width: 44ch;
    text-overflow: ellipsis;
}
.preview-textStep-card {
    white-space: nowrap;
    overflow: hidden;
    max-width: 21ch;
    text-overflow: ellipsis;
}

.node_element_clz {
    line-height: 0px;
}

.horizontal_line_item_clz {
    width: 15px;
    background-color: black;
    height: 1px;
    position: absolute;
    left: -15px;
    right: 0;
    top: 60%;
    display: block;
}

.bottom_line_category_clz {
    /* height: 17px; */
    width: 1px;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 23px;
}
.horizontal_line_category_clz {
    width: 15px;
    background-color: #ec0d0d;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    top: 15px;
    left: -15px;
}

.bottom_line_item_clz {
    height: 15px;
    width: 1px;
    background-color: black;
    position: absolute;
    left: -16px;
    right: 0;
    top: 15px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 3px;
    margin-top: 3px;
    border-radius: 19px;
    width: 33px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: ;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 3px;
    margin-top: 3px;
    border-radius: 19px;
    width: 33px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: ;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 3px;
    margin-top: 3px;
    border-radius: 19px;
    width: 33px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: ;
}

#nprogress .bar,
#nprogress .spinner {
    z-index: 999999 !important;
}
.password-toggle__2 {
    position: absolute;
    padding-top: 18px !important;
    right: 26px;
    opacity: 0.6;
}
.password-toggle__1 {
    position: absolute;
    right: 26px;
    opacity: 0.6;
}
.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}

@media (max-width: 576px) {
    .login-logo__clz {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 35vh;
    }

    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        top: 5%;
        position: absolute;
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
                clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
        width: 100%;
        height: 100vh;
    }

    .video {
        width: auto;
        height: 100vh;
    }

    .login-card {
        top: 60% !important;
        left: 50% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .login-logo__clz {
        margin-left: 28vw;
        margin-top: 2vh;
        margin-bottom: 4vh;
        width: 35vh;
    }

    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        width: 80%;
        top: 2%;
        left: 10%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
                clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
        width: 100%;
        height: 100vh;
    }

    .video {
        width: auto;
        height: 100vh;
    }

    .login-card {
        top: 55% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }

    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }

    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }

    .video {
        width: auto;
        height: 100%;
    }

    .login-card {
        top: 60% !important;
        left: 55% !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }

    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }

    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }

    .video {
        width: auto;
        height: 100vh;
    }

    .login-card {
        top: 55% !important;
        left: 55% !important;
    }
}

@media (min-width: 1200px) {
    .login-logo__clz {
        margin-left: 4vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }

    .slogan {
        margin-left: 10vw;
        width: 40vh;
        margin-top: 3vh;
    }

    .etk-logo__clz {
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
        width: 30%;
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 40vw;
    }

    .video {
        width: 120%;
        height: auto;
    }

    .login-card {
        left: 60% !important;
    }
}

.login-btn__clz {
    box-shadow: 0px 15px 20px 7px #bfbdbd !important;
}

.login-btn__clz:hover {
    background-color: white !important;
    color: black !important;
}

.login-card {
    font-family: "Poppins";
    left: 50%;
    top: 50%;
    width: 30%;
    height: 47%;
}

.login-card h3 {
    font-size: 35px;
}

.login-card .form-group label {
    font-size: 20px;
}

.password-toggle__clz {
    position: absolute;
    padding-top: 38px;
    right: 15px;
    opacity: 0.6;
}

.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}

.submit-button__clz {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    margin-left: 31%;
}

input::-webkit-input-placeholder {
    width: 100% !important;
}

input:-ms-input-placeholder {
    width: 100% !important;
}

input::placeholder {
    width: 100% !important;
}

