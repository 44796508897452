th,
td {
    text-align: center;
    vertical-align: middle;
}
.Line__CLZ {
    -moz-transform: rotate(23deg);
    -o-transform: rotate(23deg);
    -webkit-transform: rotate(23deg);
    -ms-transform: rotate(23deg);
    transform: rotate(23deg);

    border: 1px solid #ececed;
}
.item_font__CLZ {
    font: 13px / 17px "Segoe UI";
    color: rgb(73, 80, 87);
}
