.btn_annuler {
    cursor: pointer;
    border: transparent;
    background-color: transparent;

    text-align: center;
    text-decoration: underline;
    font: normal normal normal 15px/15px Poppins;
    letter-spacing: 0px;
}
.btn_valider {
    width: 107px;
    height: 47px;
}
.h5 {
    text-align: left;
    font: normal normal 600 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.saisir {
    width: 170px;
    height: 47px;
    opacity: 0.5;
}
.select {
    min-width: 170px !important;
    width: 47px !important;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #707070 !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}
.modification {
    background-color: #eeeeee;
    width: 1463px;
    height: 70px;
}
.scrollAdd__clz {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
}
.scroll__clz {
    height: 595px;
    max-height: 595px;
    overflow-y: auto;
}
.card-container__clz {
    min-height: 110px !important;
    height: 110px !important;
    background: #f3f3f3 !important;
    border: 1px solid #d4d4d4 !important;
    border-radius: 10px !important;
    opacity: 0.69 !important;
    margin-top: -15px;
}
.card-time__clz {
    min-height: 80px !important;
    height: 80px !important;
    width: 360px !important;
}
.card_time_planning__clz {
    height: 80px !important;
    min-height: 80px !important;
    width: -webkit-fill-available !important;
}
.card_title_planning {
    min-height: 40px !important;
    height: 40px !important;
    width: -webkit-fill-available !important;
}
.checkbox__clz {
    width: 25px;
    height: 25px;
    border: 1px solid var(--unnamed-color-d6d6d6);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    opacity: 1;
}
.checkbox_planning__clz {
    width: 25px;
    height: 25px;
    border: 1px solid var(--unnamed-color-d6d6d6);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    opacity: 1;
    margin-top: -5%;
    margin-right: 10px;
}
.heur__clz {
    margin-left: -25px;
}
.InputHeur__clz {
    margin-right: -100px;
}
.InputDate__clz {
    margin-left: -5%;
}
.MarginCardHeur__clz {
    margin-top: -1% !important;
    margin-left: auto !important;
}
.fieldset__clz {
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.fieldsetInterne__clz {
    border: 1px solid #dedede;
    border-radius: 5px;
    opacity: 1;
}
/* */
.header {
    padding-right: 50px;
    height: 81px;
    background: #e8e8e8 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
}
.header__input {
    width: 329px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    margin-left: 5px;
}
.header__label {
    margin-left: 5px;
    margin-right: 10px;
}
p {
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
    margin-bottom: 5px !important;
}
.body__conteneur {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
    height: 291px;
    margin-top: 10px;
}
.ant-radio-wrapper {
    margin: 10px !important;
}
.ant-radio {
    height: 19px;
    opacity: 1;
    margin-left: 25px;
}
.body__input {
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    margin-left: 5px;
}
.input__body {
    /* //padding: 0px 20px ; */
    width: 300;
    padding-bottom: 10px !important;
}
.input__body p {
    width: max-content !important;
}
