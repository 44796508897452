pp {
    text-align: left;
    font: normal normal bold 15px/14px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    text-transform: uppercase;
    opacity: 1;
}

.ant-table-wrapper {
    height: 400px;
}
.ant-table-tbody > tr > td {
    padding: 0.11%0%;
}
