.btn_ {
    color: #000000 !important;
    background-color: #ffffff;
    border-color: #74788d !important;
    border: 1px solid transparent;
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}
.arrowRight {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    width: 8px;
    height: 8px;
    transform: rotate(320deg);
}
.arrowDown {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
}
.makeStyles-tableHeadCell-4 {
    color: rgba(0, 0, 0, 0.87);
    padding: 16px 1px 16px 16px;
    font-size: 0.875rem;
    text-align: left;
    font-weight: 500;
    line-height: 1.5rem;
    border-right: 1px solid rgba(224, 224, 224, 1);
    vertical-align: inherit;
    font-weight: bold;
}
/* table {
    overflow-x: auto;
} */
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}
