.Style_image {
    width: 22px;
    height: 23px;
    background: transparent linear-gradient(180deg, #9f4500 0%, #9f4500 100%) 0%
        0% no-repeat padding-box;
}
.inpt {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn_ {
    color: #000000 !important;
    border-radius: 12px;
    border-color: #74788d !important;
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.Footercard {
    background-color: white !important;
    border-color: currentcolor rgba(0, 0, 0, 0.125) rgba(0, 0, 0, 0.125);
    border-style: none solid solid;
    border-width: 0px 1px 1px;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px 0px 12px 12px !important;
}
.Bodycard {
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: white;
}
.Headercard {
    background-color: white !important;
    margin-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: 66px !important;
}
.Footercard {
    background-color: white;
    border-color: currentcolor rgba(0, 0, 0, 0.125) rgba(0, 0, 0, 0.125);
    border-style: none solid solid;
    border-width: 0px 1px 1px;
    border-image: none 100% / 1 / 0 stretch;
    border-radius: 0px 0px 12px 12px !important;
}
.Bodycard {
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.Headercard {
    background-color: white;
    margin-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
@media (max-width: 1367px) {
    .height_ {
        height: auto;
    }
    .MuiToolbar-root {
        flex-wrap: wrap !important;
    }
    .RvYSC {
        margin-top: 0.5rem !important;
    }
    #mytable {
        min-height: 45vh !important;
    }
}
.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 35px !important;
    min-height: 35px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: unset;
    transition: unset;
}
@media screen and (max-width: 1200px) {
    .modal-dialog {
        max-width: 100%;
        max-height: 100vh;
        margin-top: -0.5vh;
    }
}
