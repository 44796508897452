.page-title-box {
    align-items: center !important;
    padding-bottom: 0% !important;
}
.nav {
    display: flex;
    height: 100%;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000d;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    opacity: 1;
    justify-content: space-evenly !important;
    align-content: center;
    padding: 10px 5px;
}
.nav-item {
    height: max-content !important;
    align-items: center !important;
    font-size: 16px;
    background: #525353 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: white !important;
}
.espace {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
/** Mobile **/
@media (max-width: 450px) {
    .nav-item {
        height: max-content !important;
        background: #525353 0% 0% no-repeat padding-box;
        opacity: 1;
        align-items: center !important;
        margin-top: 1rem !important;
        font-size: 12px;
    }
    .espace {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .filepath {
        min-width: 80% !important;
    }
}

.footer2 {
    position: inherit !important;
}

.disabledNav {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #323333;
    opacity: 0.84;
    color: black !important;
}
.disabledDiv {
    pointer-events: none;
    opacity: 0.4;
}

.filepath {
    min-width: 50%;
    border: "aqua 1px solid";
    padding-left: 20px;
    height: 36px;
    background: #f3f3f9 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}
