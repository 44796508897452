.cell_divided__clz {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    position: relative;

    background-image: linear-gradient(
        to right top,
        white 49%,
        #cccfd3 50%,
        white 51%
    );
}

.cell_topRight__clz {
    grid-column-start: 2;
    text-align: right;
    margin: auto;
}

.cell_bottomLeft__clz {
    grid-column-start: 1;
    margin: auto;
}

.advanced_image_table th {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 40px;
    border: 1px solid #cccfd3;
}
.advanced_image_table td {
    margin: 0;
    padding: 0;
    position: relative;
    border: 1px solid #cccfd3;
}
