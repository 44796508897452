.App {
    font-family: sans-serif;
    text-align: center;
    padding: 1rem;
}

.input-path {
    min-width: 50%;
    border: "aqua 1px solid";
    padding-left: 20px;
    height: 36px;
    background: #f3f3f9 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
}
.icon {
    margin-bottom: 0px !important;
}
.input_ty {
    font-size: 18px;
    margin-bottom: 0rem;
    width: 19% !important;
}
.SearchInput {
    height: "50%";
}
.btn_add_clz {
    background: #1d4afe 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: white;
    borderradius: "10px";
    height: "35px";
}
.btn_update_clz {
    background: #ff8000 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    background: "#1D4AFE";
    color: white;
    borderradius: "10px";
    height: "35px";
}
