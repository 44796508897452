.node_element_clz {
    line-height: 0px;
}

.horizontal_line_item_clz {
    width: 15px;
    background-color: black;
    height: 1px;
    position: absolute;
    left: -15px;
    right: 0;
    top: 60%;
    display: block;
}

.bottom_line_category_clz {
    /* height: 17px; */
    width: 1px;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 23px;
}
.horizontal_line_category_clz {
    width: 15px;
    background-color: #ec0d0d;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    top: 15px;
    left: -15px;
}

.bottom_line_item_clz {
    height: 15px;
    width: 1px;
    background-color: black;
    position: absolute;
    left: -16px;
    right: 0;
    top: 15px;
}
