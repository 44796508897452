.overflow_categories {
    overflow-y: scroll !important;
    /* max-height: 95vh !important; */
    overflow-x: hidden;
}
.custom_radio input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    width: 20%;
    height: 20px;
}
.form-check-label {
    margin-bottom: 0;
    width: 100%;
    margin-left: 20px;
}
