.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 3px;
    margin-top: 3px;
    border-radius: 19px;
    width: 33px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: ;
}
