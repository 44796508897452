/* .editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 5px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 5px 5px;
    border: 1px solid #e21616;
    border-radius: 2px;
    margin: 0;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #25da25;
} */
.editable-cell {
    position: fixed;
}
.editable-cell-value-wrap {
    width: 200px !important;
    height: 25px !important;
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    padding: 0% !important;
    /* border: none !important; */
}
.editable-row {
    width: 200px !important;
    /* border: #1fb7e6 1px solid;  */
    margin: 0px !important;
    padding: 0% !important;
}
.ant-input:hover {
    /* border-color: #e2e2e2fb !important; */
    width: 200px !important;
    box-shadow: none !important;
    border: none !important;
}

.ant-input sm {
    border-color: #e2e2e2fb !important;
    width: 200px !important;
    box-shadow: none !important;
    border: none !important;
}
.ant-input sm ::placeholder {
    /* border-color: #e2e2e2fb !important; */
    outline: 0;
    padding: 50px;
    /* border: none !important; */
}
/* .ant-input ::placeholder{
    color:red !important
} */
td.ant-table-cell-row-hover {
    background: none !important;
}
.ant-table-row-level-1 {
    width: 200px !important;
    /* border: #ff1a8c 1px solid; */
    padding: 0% !important;
    border: none !important;
}
/* .ant-form-item{
    width: 200px !important;
    border: #cdf009 1px solid;
    padding: 0px !important;
    margin: 0px !important;
} */
.ant-form-item-control-input-content {
    width: 200px !important;
    /* border: none !important; */
    /* border: #e2e2e2 1px solid !important; */
    padding: 0px !important;
    margin: 0px !important;
}

/* .ant-form-item-control-input{ width: 200px !important; 
    border: #af1818 1px solid !important;
    padding: 0px !important;
    margin: 0px !important;} */

.ant-row {
    width: 200px !important;
    /* border: #ce1515 1px solid;  */
    padding: 0% !important;
    border: none !important;
}
.ant-form-item-row {
    width: 200 !important;
    height: 25 !important;
    /* border: #050000 1px solid; */
    padding: 0% !important;
    border: none !important;
}
.ant-input-sm {
    padding: 0px 7px;
    border: none;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}
.ant-table-thead > tr > th {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #ffffff;
    /* border: 1px solid #E2E2E2 !important; */
    border-bottom: 1px solid #c6c6c6 !important;
    transition: background 0.3s ease;
}
.ant-table-thead {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e2 !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}
.ant-table-tbody > tr > td {
    border-bottom: 1px solid #e2e2e2;
    transition: background 0.3s;
}

.categorie__filtre {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.categorie__options {
    width: 218px;
}
.multipleEdit__button {
    width: 174px;
    height: 45px;
    background: #2f2f2f 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
    margin-left: 50px;
    margin-top: 18px;
    margin-bottom: 10px;
    color: #fcfcfc;
}
.filtre__box {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e2;
    opacity: 1;
    border-radius: 4px;
}
.filtre {
    width: 219px;
    margin-left: 50px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.filtre__box > p {
    /* text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 50px; */

    padding: 20px;
    border-right: 1px solid #e2e2e2;
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
label {
    margin: 0px;
}

.ant-table-pagination.ant-pagination {
    margin: 0;
    display: none;
}

.ant-table-tbody > tr > td {
    border-bottom: 1px solid #e2e2e2;
    transition: background 0.3s;
    text-align: left;
    font: normal normal normal 16px/20px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
.ant-table-thead > tr > th {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #ffffff;
    /* border: 1px solid #E2E2E2 !important; */
    border-bottom: 1px solid #c6c6c6 !important;
    transition: background 0.3s ease;
    height: 22px;
    font: normal normal 600 17px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
.kwBQUT {
    font-family: "Segoe UI Semibold";
    color: #2b2828;
    font-size: 16px;
    /* width: 116px; */
    height: 21px;
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
.css-1okebmr-indicatorSeparator {
    display: none;
}
.css-tj5bde-Svg {
    height: 25px;
    fill: #2b2828 !important;
}

.ant-table-wrapper {
    clear: both;

    height: 250px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    opacity: 1;
}
.ant-table-wrapper::-webkit-scrollbar {
    width: 0 !important;
}
.ant-form-item {
    margin: 0px;
}
