.borderless_input__CLZ {
    border: none;
    width: calc(100% - 15px);
    border-radius: 6px;
    height: 100%;
}

.borderless_input__CLZ:focus {
    outline: none;
}

.container_quantity {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.CompositionDeBae_clz {
    width: 100%;
    hyphens: auto;
}
